/* eslint-disable */
// noinspection DuplicatedCode

import {useNavigate, useParams} from "react-router-dom";
import {
    Action, AddOrEditRecordItem, AddOrEditRecordsBody,
    CompositeReport,
    CompositeReportApi, DateReport,
} from "../../../../api/composite/report/CompositeReportApi";
import {useEffect, useState} from "react";
import PageHeader from "../../../../components/layout/PageHeader";
import PageContent from "../../../../components/layout/PageContent";
import {showNotification} from "@mantine/notifications";
import {IconCheck, IconX} from "@tabler/icons-react";
import {useDisclosure} from "@mantine/hooks";
import {Device} from "../../../../api/device/DeviceApi";
import NiceCommentsDialog from "../../../../components/comments/dialog/nice/NiceCommentsDialog";
import EditSimpleDataDialog from "../details/EditSimpleDataDialog";
import CompositeReportExcelTableDetailsMenu from "./CompositeReportExcelTableDetailsMenu";
import {modals} from "@mantine/modals";
import ExcelView from "../../../../components/composite/report/excel/ExcelView";
import Page from "../../../../models/api/Page";
import CompositeReportWorkCards from "../work/CompositeReportWorkCards";

function CompositeReportExcelTableDetails() {
    const { compositeReportId } = useParams();
    const [_, setLoading] = useState(false);
    const [report, setReport] = useState<CompositeReport>();
    const [device, setDevice] = useState<Device>();
    const [nextPage, setNextPage] = useState<Page<CompositeReport>>()
    const nav = useNavigate();
    const [isCardsVisible, setIsCardsVisible] = useState(false);

    const [isCommentsDialog, commentsDialog] = useDisclosure();
    const [isEditSimpleDataDialogOpen, editSimpleDataDialog] = useDisclosure();

    const [isSuggestionMode, setSuggestionMode] = useState(false);
    const [suggestion, setSuggestion] = useState<CompositeReport>();

    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ]

    const loadData = (reportFromApi: CompositeReport) => {
        setReport(reportFromApi);
        setDevice(reportFromApi.devices[0]);
    }

    const reloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            icon: <IconX/>,
            color: 'red'
        });
    }

    const reload = () => {
        if (!compositeReportId) return;

        startLoading();

        CompositeReportApi.details(compositeReportId)
            .then(loadData)
            .catch(reloadError)
            .finally(stopLoading);
    }

    const reloadNextPage = () => {
        startLoading();

        CompositeReportApi.search({approvedBy: '<NULL>', includeArchived: false, page: 1, limit: 1, sortDir: 'desc', sortBy: 'title'})
            .then(setNextPage)
            .catch((e) => console.error(e))
            .finally(stopLoading);
    }

    const doApproveSuccess = () => {
        showNotification({
            title: 'Zatwierdzono raportów',
            message: `Pomyślnie zatwierdzono raport.`,
            color: 'green'
        })
    }

    const doApproveError = () => {
        showNotification({
            title: `Nie udało się zatwierdzić raportu`,
            message: 'Nie można zatwierdzić ponownie tego samego raportu. Jeśli uważasz, że to błąd skontaktuj się z administratorem.',
            color: 'red'
        });
    }

    const doApprove = () => {
        const action: Action = { action: 'approve', selectedElements: [report?.id!!] };

        startLoading();

        CompositeReportApi.performAction(action)
            .then(doApproveSuccess)
            .then(reload)
            .catch(doApproveError)
            .finally(stopLoading);
    }

    const approve = () => {
        if (!report) {
            showMissingReportNotification();
            return;
        }

        modals.openConfirmModal({
            title: "Zatwierdzanie raportów",
            children: `Czy chcesz zatwierdzić ${report?.title ?? '???'}?`,
            labels: { confirm: 'Zatwierdź', cancel: 'Anuluj' },
            confirmProps: {color: 'green'},
            centered: true,
            onConfirm: doApprove
        });
    }

    const doSendReportSuccess = () => {
        showNotification({
            title: `Utworzono prototyp wiadomości`,
            message: 'Możesz teraz edytować tą wiadomość, a później ją wysłać',
            color: 'green'
        });
    }

    const doSendReportError = () => {
        showNotification({
            title: `Nie udało się wysłać raportu`,
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const doSendReport = () => {
        const action = { action: 'send', selectedElements: [report?.id!!]} as Action;

        startLoading();

        CompositeReportApi.performAction(action)
            .then(doSendReportSuccess)
            .then(reload)
            .catch(doSendReportError)
            .finally(stopLoading);
    }

    const skipReport = () => {
        const nextReportId = nextPage?.content?.at(0)?.id;

        if (!nextReportId) {
            throw new Error('There\'s no next page.');
        }

        nav(`/composite/report/excel-details/${nextReportId}`);
    }

    const doApproveAndNext = () => {
        const action: Action = { action: 'approve', selectedElements: [report?.id!!] };

        startLoading();

        CompositeReportApi.performAction(action)
            .then(doApproveSuccess)
            .then(skipReport)
            .catch(doApproveError)
            .finally(stopLoading);
    }

    const approveAndNext = () => {
        modals.openConfirmModal({
            title: "Zatwierdzanie raportu",
            children: 'Czy chcesz zatwierdzić ten raport i przejść do następnego',
            labels: {confirm: `Tak`, cancel: 'Nie'},
            confirmProps: {color: 'green'},
            centered: true,
            onConfirm: doApproveAndNext
        });
    }

    const showMissingReportNotification = () => showNotification({
        title: 'Nie udało się wysłać',
        message: 'Proszę odświeżyć stronę, jeśli problem nie ustąpi proszę skontaktować się z administratorem',
        color: 'red',
        icon: <IconX/>
    });

    const sendReport = () => {
        if (!report) {
            showMissingReportNotification();
            return;
        }

        modals.openConfirmModal({
            title: "Wysyłanie raportu",
            children: (<>
                Czy na pewno chcesz wysłać {report?.title ?? '<Raport bez tytułu>'}?<br/>
                Spowoduje to utworzenie prototypu wiadomości, którą można później edytować
            </>),
            labels: {confirm: `Wyślij`, cancel: 'Nie wysyłaj'},
            confirmProps: {color: 'green'},
            centered: true,
            onConfirm: () => doSendReport()
        });
    }

    useEffect(reload, [compositeReportId]);

    useEffect(reloadNextPage, [compositeReportId]);

    const reloadSuggestionError = () => {
        showNotification({
            title: 'Nie udało się pobrać sugestii',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        });
    }

    const mapDateReportsToItems = (dateReports: DateReport[]) => {
        let items: AddOrEditRecordItem[] = [];

        for (const dateReport of dateReports) {
            for (const record of dateReport.records as any[]) {
                const item: AddOrEditRecordItem = {
                    dateReportId: dateReport.id,
                    hour: record["_original_time"],
                    temp1: record["Temp1"],
                    temp2: record["Temp2"]
                }

                items = [...items, item];
            }
        }

        return items;
    }

    const onSaveSuggestionSuccess = () => {
        showNotification({
            title: 'Zapisano!',
            message: 'Zapisano sugestie',
            color: 'green',
            icon: <IconCheck/>
        });
    }

    const doSaveSuggestion = () => {
        if (!isSuggestionMode || !suggestion) {
            showNotification({
                title: 'Aby zapisać, musisz być w trybie sugestii.',
                message: 'Jeśli uważasz, że to błąd - proszę skontaktować się z administratorem',
                color: 'red',
                icon: <IconX/>
            });

            return;
        }

        const dateReports = suggestion?.sections?.flatMap(x => x.content) ?? [];

        const payload: AddOrEditRecordsBody = {
            compositeReportId: report?.id!!,
            items: mapDateReportsToItems(dateReports)
        }

        CompositeReportApi.addOrEditRecords(payload)
            .then(onSaveSuggestionSuccess)
            .then(() => setSuggestionMode(false))
            .then(reload)
            .catch(e => { throw e });
    }

    const saveSuggestion = () => {
        modals.openConfirmModal({
            title: "Sugestia",
            children: `Czy na pewno chcesz zapisać tą sugestię? Spowoduje to wczytanie wszystkich sugerowanych (zielonych) pól.?`,
            labels: { confirm: `Tak, zapisz.`, cancel: 'Anuluj' },
            confirmProps: {color: 'yellow'},
            centered: true,
            onConfirm: doSaveSuggestion
        });
    }

    const reloadSuggestion = () => {
        if (!isSuggestionMode) {
            setSuggestion(undefined);
            return;
        }

        startLoading();

        CompositeReportApi.getSuggestion({compositeReportId: report?.id!!})
            .then(setSuggestion)
            .catch(reloadSuggestionError)
            .finally(stopLoading);
    }

    useEffect(reloadSuggestion, [isSuggestionMode, compositeReportId]);

    return (
        <>
            <PageHeader
                title={`Szczegóły raportu ${report?.title ?? ''}`}
                pageTitle={report?.title ?? 'TermoControl'}
                url={'/composite/report'}
                action={<CompositeReportExcelTableDetailsMenu
                    isSuggestionMode={isSuggestionMode}
                    setSuggestionMode={setSuggestionMode}
                    saveSuggestion={saveSuggestion}
                    toggleCards={() => setIsCardsVisible(!isCardsVisible)}
                    editData={editSimpleDataDialog.open}
                    editComments={commentsDialog.open}
                    approveReport={approve}
                    sendReport={sendReport}
                    deleteReport={() => showNotification({title: 'Usuń z poziomu tabeli', message: '...'})}
                    approveAndNext={approveAndNext}
                />}
            />

            <PageContent>
                <CompositeReportWorkCards
                    isVisible={true}
                    report={report}
                    reloadReport={reload}
                />

                <ExcelView
                    reload={reload}
                    compositeReport={isSuggestionMode ? suggestion : report }
                    device={device}
                    disableEditing={isSuggestionMode}
                />
            </PageContent>

            <NiceCommentsDialog
                isOpen={isCommentsDialog}
                justClose={commentsDialog.close}
                closeAndUpdate={() => { commentsDialog.close(); reload(); }}
                compositeReport={report}
            />

            <EditSimpleDataDialog
                isOpen={isEditSimpleDataDialogOpen}
                justClose={editSimpleDataDialog.close}
                closeAndUpdate={() => { reload(); editSimpleDataDialog.close(); }}
                compositeReport={report}
            />
        </>
    );
}

export default CompositeReportExcelTableDetails;

// TODO: CompositeReportExcelTableDetails
//  - Pomijanie nie działa dla ostatniej strony, nie widzi jej z powodu pominięcia jednej zawsze.