import Page from "../../../models/api/Page";
import {HttpClient} from "../../_http/HttpClient";
import {Api} from "../../Api";

type OptionalString = string | null | undefined;

export type DeviceMessage = {
    id: string;
    unit: OptionalString;
    message: OptionalString;
}

export type CreatePayload = {
    unit: string;
    message: string;
    date: string;
}

class DeviceMessageApi {
    static get(): Promise<Page<DeviceMessage>> {
        const url = Api.getUrl('/device/message/');
        return HttpClient.get(url, {});
    }

    static deleteById(id: string): Promise<Response> {
        const url = Api.getUrl("/device/message/");
        return HttpClient.deleteAndExpectNoContent(url, { id }, {});
    }

    static create(data: CreatePayload): Promise<DeviceMessage> {
        const url = Api.getUrl('/device/message');
        return HttpClient.post(url, {}, {...data});
    }
}

export default DeviceMessageApi;