import PageContent from "../../../components/layout/PageContent";
import React, {useEffect, useState} from "react";
import Page from "../../../models/api/Page";
import DeviceMessageApi, {DeviceMessage} from "../../../api/device/message/DeviceMessageApi";
import {DataTable, DataTableColumn, DataTableSortStatus, useDataTableColumns} from "mantine-datatable";
import Cell from "../../../components/table/Cell";
import DateView from "../../../components/date/DateView";
import PageHeader from "../../../components/layout/PageHeader";
import PaginationFooter from "../../../components/pagination/PaginationFooter";
import {useSessionStorage} from "@mantine/hooks";

const columns: DataTableColumn[] = [
    {
        title: '_id',
        accessor: 'id',
        toggleable: true,
        defaultToggle: false,
        sortable: true
    },

    {
        title: 'Data',
        accessor: 'date',
        toggleable: true,
        render: (record) => <DateView date={(record as any)?.date} />,
        sortable: true
    },

    {
        title: '#',
        accessor: 'unit',
        toggleable: true,
        render: (record) => <Cell orOptional={record.unit} />,
        sortable: true
    },

    {
        title: 'Komunikat',
        accessor: 'message',
        toggleable: true,
        render: (record) => <Cell orOptional={record.message} />,
        sortable: true
    }
];

function DeviceMessagePage() {
    const [page, setPage] = useState<Page<DeviceMessage>>();
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [limit, setLimit] = useState<number>(50);
    const [selectedRecords, setSelectedRecords] = useState<DeviceMessage[]>([]);

    const [sortStatus, setSortStatus] = useSessionStorage<DataTableSortStatus<DeviceMessage>>({
        key: 'DeviceMessage_sortStatus',
        defaultValue: {
            columnAccessor: 'date',
            direction: 'asc'
        }
    });

    const key = "DeviceMessage-table-key";

    const { effectiveColumns } = useDataTableColumns({
        key, columns
    });

    const reload = () => {
        DeviceMessageApi.get()
            .then(setPage)
            .catch(() => {})
    }

    useEffect(reload, []);

    return (
        <>
            <PageHeader
                title="Komunikaty z urządzeń"
                action={<div></div>}
                url="/device/message"
            />

            <PageContent>
                <DataTable
                    height="60vh"
                    withColumnBorders
                    styles={{ header: { background: 'white' } }}
                    storeColumnsKey={key}
                    minHeight="150px"
                    fetching={false}
                    highlightOnHover
                    columns={effectiveColumns}
                    style={{marginBottom: '50px'}}
                    backgroundColor={'transparent'}
                    noRecordsText={"Pusto..."}
                    loaderBackgroundBlur={1}
                    records={page?.content ?? []}
                    selectedRecords={selectedRecords}
                    onSelectedRecordsChange={setSelectedRecords}
                    sortStatus={sortStatus}
                    onSortStatusChange={setSortStatus}
                />

                <PaginationFooter
                    page={page}
                    setLimit={setLimit}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                    limit={limit}
                />
            </PageContent>
        </>
    );
}

export default DeviceMessagePage;