import {useForm} from "@mantine/form";
import {DateInput} from "@mantine/dates";
import {Button, Modal, Stack, Textarea} from "@mantine/core";
import {Device} from "../../../api/device/DeviceApi";
import DeviceMessageApi, {CreatePayload} from "../../../api/device/message/DeviceMessageApi";
import {Notifications, showNotification} from "@mantine/notifications";

export type CreateDeviceMessageDialogProps = {
    device?: Device;
    isOpen: boolean;
    justClose: () => void;
    closeAndReload: () => void;
}

const format = (dateTime: string) => {
    const date = new Date(dateTime);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
}

function CreateDeviceMessageDialog(props: CreateDeviceMessageDialogProps) {
    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            date: '',
            message: ''
        }
    });

    const onFailed = () => {
        showNotification({
            title: 'Nie udało się zapisać komunikatu',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const doSubmit = (values: any) => {
        const unit = props.device?.unit;

        if (!unit) { return; }

        const data: CreatePayload = {
            message: values.message,
            date: values.date,
            unit: unit,
        };

        DeviceMessageApi.create(data)
            .then(props.closeAndReload)
            .catch(onFailed);
    }

    return (
        <Modal centered opened={props.isOpen} onClose={props.justClose} title="Dodaj komunikat do urządzenia">
            <form onSubmit={form.onSubmit(doSubmit)}>
                <Stack>
                    <DateInput
                        withAsterisk
                        valueFormat="DD-MM-YYYY"
                        label="Od"
                        placeholder="dd-mm-yyyy"
                        key={form.key('date')}
                        { ...form.getInputProps('date') }
                    />

                    <Textarea
                        withAsterisk
                        label="Komunikat"
                        placeholder="Komunikat..."
                        key={form.key('message')}
                        { ...form.getInputProps('message') }
                    />

                    <Button type="submit">
                        Zapisz
                    </Button>
                </Stack>
            </form>
        </Modal>
    );
}

export default CreateDeviceMessageDialog;