import {CompositeReport} from "../../../../api/composite/report/CompositeReportApi";
import {ActionIcon, SimpleGrid, Transition} from "@mantine/core";
import CommentsPreviewCard from "../../../workspace/work-panel/cards/comments/preview/CommentsPreviewCard";
import AddCommentPresetsCard from "../../../workspace/work-panel/cards/add-comment-presets/AddCommentPresetsCard";
import AddSimpleCommentCard from "../../../workspace/work-panel/cards/add-simple-comment/AddSimpleCommentCard";
import CardPlaceholder from "../../../workspace/work-panel/cards/CardPlaceholder";
import {IconNote, IconTrash} from "@tabler/icons-react";
import CardItem from "../../../workspace/work-panel/cards/CardItem";
import CardFooterButton from "../../../workspace/work-panel/cards/CardFooterButton";
import CardWrapper from "../../../workspace/work-panel/cards/CardWrapper";
import DeviceCard from "../../../../components/device/cards/DeviceCard";

export type CompositeReportWorkCardsAttrs = {
    isVisible: boolean;
    report: CompositeReport | undefined;
    reloadReport: () => void;
}

function CompositeReportWorkCards(attrs: CompositeReportWorkCardsAttrs) {
    return (
        <Transition mounted={attrs.isVisible} transition='pop'>
            { styles =>
                <SimpleGrid style={styles} mb="xl" cols={{base: 1, sm: 1, md: 2, lg: 3}} spacing={{base: 10, sm: 'xl'}} verticalSpacing={{base: 'sm', sm: 'md'}}>
                    {/*<ValidationCard*/}
                    {/*    compositeReport={attrs.report}*/}
                    {/*/>*/}

                    <CommentsPreviewCard
                        reload={attrs.reloadReport}
                        compositeReport={attrs.report}
                    />

                    <DeviceCard reload={attrs.reloadReport} device={attrs.report?.devices?.at(0)}/>

                    <AddSimpleCommentCard
                        reload={attrs.reloadReport}
                        compositeReport={attrs.report}
                    />
                </SimpleGrid>
            }
        </Transition>
    )
}

export default CompositeReportWorkCards;