/* eslint-disable react-hooks/exhaustive-deps */
import {Button, Card, Flex, Group, Stack, Text} from "@mantine/core";
import {Device} from "../../../api/device/DeviceApi";
import React from "react";
import DeviceEditDialog from "../../../pages/device/DeviceEditDialog";
import {useDisclosure} from "@mantine/hooks";

function Field(attrs: {title: string, description: string}) {
    return (
        <Card.Section withBorder inheritPadding p='md'>
            <Group justify="space-between">
                <Stack gap={0}>
                    <Text>
                        {attrs.title}
                    </Text>

                    <Text c="dimmed" size="sm">
                        {attrs.description}
                    </Text>
                </Stack>
            </Group>
        </Card.Section>
    );
}

function DeviceCard(attrs: {device?: Device, reload: () => void;}) {
    const [isEditDeviceModalOpen, editDeviceModal] = useDisclosure();

    if (!attrs.device) return null;

    const doEdit = () => {
        editDeviceModal.open();
    }

    return (
        <>
            <Card withBorder pos="relative" radius="md">
                <Flex justify="space-between" direction="column" h="100%">
                    <div>
                        <Card.Section p={'md'}>
                            <Text fw={500}>Informacje o urządzeniu</Text>
                        </Card.Section>

                        <Field title="Nazwa urządzenia" description={attrs.device.name ?? '-'}/>

                        <Field title="Jednostka" description={attrs.device.unit ?? '-'}/>

                        <Field title="Zakres temperatur" description={`${attrs.device.minAllowableTemp ?? '-'} <> ${attrs.device.maxAllowableTemp ?? '-'}`}/>
                    </div>

                    <Card.Section withBorder>
                        <Button variant="transparent" fullWidth onClick={doEdit}>
                            Edytuj
                        </Button>
                    </Card.Section>
                </Flex>
            </Card>

            <DeviceEditDialog
                isOpen={isEditDeviceModalOpen}
                onUpdated={() => { editDeviceModal.close(); attrs.reload(); }}
                onClosedWithoutUpdate={editDeviceModal.close}
                device={attrs.device}
            />
        </>
    );
}

export default DeviceCard;

// TODO: DeviceCard (DeviceView.tsx component)
//  Edit this data (unit, title) with simplified dialog.
//  Add some fields: [minMaxTemp, stamps]