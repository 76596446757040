/* eslint-disable */
import {useForm} from "@mantine/form";
import {Device, DeviceApi} from "../../api/device/DeviceApi";
import {useEffect, useState} from "react";
import {Button, Flex, Modal, NumberInput, Stack, Textarea, TextInput} from "@mantine/core";
import {showNotification} from "@mantine/notifications";

export type DeviceEditDialogAttrs = {
    device?: Device;

    isOpen: boolean;
    onUpdated: () => void;
    onClosedWithoutUpdate: () => void;
}

type EditDeviceType = {
    unit: string,
    name: string,
    description?: string,
    location?: string,
    generator?: string,
    ftpDirectory?: string,
    thermostat?: string,
    points?: string,
    clientId?: string,
    minAllowableTemp?: number,
    maxAllowableTemp?: number,
    serialNumber?: number
}

function DeviceEditDialog(attrs: DeviceEditDialogAttrs) {
    const [loading, setLoading] = useState(false);

    const startLoading = () => setLoading(true);
    const stopLoading = () => setLoading(false);

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            unit: '',
            name: '',
            description: undefined,
            location: undefined,
            generator: undefined,
            ftpDirectory: undefined,
            thermostat: undefined,
            points: undefined,
            minAllowableTemp: undefined,
            maxAllowableTemp: undefined,
            serialNumber: undefined
        } as EditDeviceType,

        validate: {
            unit: (e) => e.length > 2 ? null : 'Przynajmniej dwa znaki',
            name: (e) => e.length > 2 ? null : 'Przynajmniej dwa znaki'
        }
    });

    const loadData = () => {
        form.setValues({
            unit: attrs.device?.unit ?? '',
            name: attrs.device?.name ?? '',
            description: attrs.device?.description ?? '',
            location: attrs.device?.location ?? '',
            generator: attrs.device?.generator ?? '',
            ftpDirectory: attrs.device?.ftpDirectory ?? '',
            thermostat: attrs.device?.thermostat ?? '',
            points: attrs.device?.points ?? '',
            minAllowableTemp: attrs.device?.minAllowableTemp,
            maxAllowableTemp: attrs.device?.maxAllowableTemp
        });
    }

    const doSubmitError = () => {
        showNotification({
            title: 'Nie udało się zapisać zmian',
            message: 'Jeśli problem będzie się utrzymywał, proszę skontaktować się z administratorem',
            color: 'red'
        });

        form.setFieldError('unit', 'Nie udało się zapisać zmian.');
    }

    const doSubmitSuccess = () => {
        showNotification({
            title: 'Zapisano!',
            message: 'Zmiany zostały zapisane',
            color: 'green'
        });
    }

    const doSubmit = (values: EditDeviceType) => {
        startLoading();

        const editPayload = {
            id: attrs.device?.id ?? '',
            clientId: attrs.device?.client?.id,
            ...values
        }

        DeviceApi.edit(editPayload)
            .then(doSubmitSuccess)
            .then(attrs.onUpdated)
            .catch(doSubmitError)
            .finally(stopLoading);
    }

    useEffect(loadData, [attrs.device]);

    return (
        <Modal opened={attrs.isOpen} onClose={attrs.onClosedWithoutUpdate} title="Edycja urządzenia">
            <form onSubmit={form.onSubmit(doSubmit)}>
                <Stack>
                    <TextInput
                        withAsterisk
                        label="Jednostka"
                        placeholder="Odpowiednik <UNIT> z raportów"
                        variant="filled"
                        key={form.key('unit')}
                        { ...form.getInputProps('unit') }
                    />

                    <TextInput
                        withAsterisk
                        label="Nazwa urządzenia"
                        placeholder="Nazwa urządzenia"
                        variant="filled"
                        key={form.key('name')}
                        { ...form.getInputProps('name') }
                    />

                    <Textarea
                        label="Opis"
                        placeholder="Dowolny opis urządzenia"
                        variant="filled"
                        minRows={3}
                        resize={'vertical'}
                        key={form.key('description')}
                        { ...form.getInputProps('description') }
                    />

                    {/*<TextInput*/}
                    {/*    label="Lokalizacja"*/}
                    {/*    placeholder="Gdzie te urządzenie się znajduje?"*/}
                    {/*    variant="filled"*/}
                    {/*    key={form.key('location')}*/}
                    {/*    { ...form.getInputProps('location') }*/}
                    {/*/>*/}

                    <TextInput
                        label="Folder FTP"
                        placeholder="Gdzie będą zapisywane wygenerowane raporty"
                        variant="filled"
                        key={form.key('ftpDirectory')}
                        { ...form.getInputProps('ftpDirectory') }
                    />

                    <TextInput
                        label="Termostat"
                        placeholder=""
                        variant="filled"
                        key={form.key('thermostat')}
                        { ...form.getInputProps('thermostat') }
                    />

                    <TextInput
                        label="Generator"
                        placeholder=""
                        variant="filled"
                        key={form.key('generator')}
                        { ...form.getInputProps('generator') }
                    />

                    <Textarea
                        label="Uwagi"
                        placeholder="Uwagi do tego urządzenia"
                        variant="filled"
                        minRows={3}
                        resize={'vertical'}
                        key={form.key('points')}
                        { ...form.getInputProps('points') }
                    />

                    <Flex gap="md">
                        <NumberInput
                            step={0.1}
                            label={"Min."}
                            key={form.key('minAllowableTemp')}
                            { ...form.getInputProps('minAllowableTemp') }
                        />

                        <NumberInput
                            step={0.1}
                            label={"Maks."}
                            key={form.key('maxAllowableTemp')}
                            { ...form.getInputProps('maxAllowableTemp') }
                        />
                    </Flex>

                    <TextInput
                        label="Numer seryjny"
                        placeholder=""
                        variant="filled"
                        key={form.key('serialNumber')}
                        { ...form.getInputProps('serialNumber') }
                    />

                    <Button type='submit' loading={loading}>
                        Zapisz
                    </Button>
                </Stack>
            </form>
        </Modal>
    );
}

export default DeviceEditDialog;
